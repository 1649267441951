var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Administrador de vacantes"}},[_c('div',{staticClass:"px-5 py-3"},[_c('div',{staticClass:"row",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col-12 col-md-10"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('BtnAddCardTable',{attrs:{"text":"Nueva Vacante"},on:{"click":function($event){return _vm.$router.push({
              name: 'NuevaVacante',
            })}}})],1)])]),_c('div',{staticClass:"table-responsive px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_cargos"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Vacante")]),_c('th',[_vm._v("Pre-selección")]),_c('th',[_vm._v("Selección")]),_c('th',[_vm._v("Vinculación")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Opciones")])])]),_c('tbody',_vm._l((_vm.listaDeVacantes),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.cargo.nombre))]),_c('td',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",attrs:{"title":"Ver información de la vacante","to":{
                name: 'InformacionVacante',
                params: { id: item.id },
              }}},[_c('i',{staticClass:"fas fa-circle-arrow-right mx-auto"})])],1),_c('td',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",attrs:{"title":"Ver proceso de pre-selección","to":{
                name: 'ProcesoDePreseleccion',
                params: { vacanteId: item.id },
              }}},[_c('i',{staticClass:"fas fa-circle-arrow-right mx-auto"})])],1),_c('td',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",attrs:{"title":"Ver proceso de selección","to":{
                name: 'ProcesoDeSeleccion',
                params: { vacanteId: item.id },
              }}},[_c('i',{staticClass:"fas fa-circle-arrow-right mx-auto"})])],1),_c('td',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",attrs:{"title":"Ver vinculación de postulantes","to":{
                name: 'VinculacionDePostulantes',
                params: { vacanteId: item.id },
              }}},[_c('i',{staticClass:"fas fa-circle-arrow-right mx-auto"})])],1),_c('td',[_c('div',{staticStyle:{"min-width":"75px","display":"flex","justify-content":"center"}},[(item.estado != null)?_c('div',{staticClass:"pointer",on:{"click":function($event){$event.preventDefault();return _vm.toggleState(item)}}},[(item.estado == 1)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Activa")]):_c('span',{staticClass:"badge badge-danger"},[_vm._v("Inactiva")])]):_c('div',[_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin"})])])])]),_c('td',{staticClass:"options"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-danger",attrs:{"title":"Editar vacante","to":{ name: 'EditarVacante', params: { id: item.id } }}},[_c('i',{staticClass:"fas fa-pen mx-auto"})])],1)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }