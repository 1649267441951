<script>
import { core } from "../../../config/pluginInit";
import CardTable from "@/components/cardTable/CardTable.vue";
import BtnAddCardTable from "@/components/btnCardTable/btnAddCardTable.vue";
export default {
  name: "AdministradorDeVacantes",
  data() {
    return {
      listaDeVacantes: [],
      dataCards: [
        {
          title: "Activas",
          value: () =>
            this.listaDeVacantes.filter((item) => item.estado).length,
          iconName: "check-circle",
          color: "#17a76c",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Activa").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Inactivas",
          value: () =>
            this.listaDeVacantes.filter((item) => !item.estado).length,
          iconName: "ban",
          color: "rgb(242, 19, 67)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Inactiva").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
      ],
    };
  },
  components: {
    CardTable,
    BtnAddCardTable,
  },
  async mounted() {
    this.$isLoading(true);
    await this.getListaDeVacantes();
    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_cargos")) {
      window.$("#datatable_cargos").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_cargos").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    async getListaDeVacantes() {
      const response = await this.$store.getters.fetchGet({
        path: `Vacante/empresa/${this.$store.getters.userLoggedIn.empresa.id}`,
      });
      this.listaDeVacantes = await response.json();
    },
    async toggleState(vacante) {
      const id = vacante.id;
      vacante.estado = null;
      const response = await this.$store.getters.fetchPut({
        path: `vacante/HiddenToggle/${id}`,
      });
      const result = await response.json();
      vacante.estado = result.estado;
    },
  },
};
</script>

<template>
  <HXContentCard title="Administrador de vacantes">
    <div class="px-5 py-3">
      <div class="row" style="gap: 1rem">
        <div class="col-12 col-md-10">
          <!-- linar-style cursor glow -->
          <CardTable :data="dataCards" />
        </div>
        <!-- btn crear vacante -->
        <div class="col-12 col-md">
          <BtnAddCardTable
            text="Nueva Vacante"
            @click="
              $router.push({
                name: 'NuevaVacante',
              })
            "
          />
        </div>
      </div>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_cargos"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Vacante</th>
            <th>Pre-selección</th>
            <th>Selección</th>
            <th>Vinculación</th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaDeVacantes" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.cargo.nombre }}</td>
            <td>
              <router-link
                v-b-tooltip.hover
                title="Ver información de la vacante"
                class="btn btn-primary"
                :to="{
                  name: 'InformacionVacante',
                  params: { id: item.id },
                }"
              >
                <i class="fas fa-circle-arrow-right mx-auto"></i>
              </router-link>
            </td>

            <td>
              <router-link
                v-b-tooltip.hover
                title="Ver proceso de pre-selección"
                class="btn btn-primary"
                :to="{
                  name: 'ProcesoDePreseleccion',
                  params: { vacanteId: item.id },
                }"
              >
                <i class="fas fa-circle-arrow-right mx-auto"></i>
              </router-link>
            </td>
            <td>
              <router-link
                v-b-tooltip.hover
                title="Ver proceso de selección"
                class="btn btn-primary"
                :to="{
                  name: 'ProcesoDeSeleccion',
                  params: { vacanteId: item.id },
                }"
              >
                <i class="fas fa-circle-arrow-right mx-auto"></i>
              </router-link>
            </td>
            <td>
              <router-link
                v-b-tooltip.hover
                title="Ver vinculación de postulantes"
                class="btn btn-primary"
                :to="{
                  name: 'VinculacionDePostulantes',
                  params: { vacanteId: item.id },
                }"
              >
                <i class="fas fa-circle-arrow-right mx-auto"></i>
              </router-link>
            </td>
            <td>
              <div
                style="min-width: 75px; display: flex; justify-content: center"
              >
                <div
                  @click.prevent="toggleState(item)"
                  class="pointer"
                  v-if="item.estado != null"
                >
                  <span v-if="item.estado == 1" class="badge badge-success"
                    >Activa</span
                  >
                  <span v-else class="badge badge-danger">Inactiva</span>
                </div>
                <div v-else>
                  <span>
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                </div>
              </div>
            </td>
            <td class="options">
              <router-link
                v-b-tooltip.hover
                title="Editar vacante"
                class="btn btn-danger"
                :to="{ name: 'EditarVacante', params: { id: item.id } }"
              >
                <i class="fas fa-pen mx-auto"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>

<style scoped>
.options {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
